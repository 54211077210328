// Package imports
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Col, Row } from 'antd';

// Project imports
import "../index.css"
import A8Utils from '../../../utils/A8Utils';
import PrimaryButtonModal from './PrimaryButtonModal';
import DangerButtonModal from './DangerButtonModal';


const GridActionButtonsCustomComp = (props) => {
    const { component, value, isBuilder, thiz } = props;
    const [ready, setReady] = useState(false);
    const [selectedButton, setSelectedButton] = useState();
    const [showPositiveModal, setShowPositiveModal] = useState(false);
    const [showNegativeModal, setShowNegativeModal] = useState(false);
    const [approveDisabled, setApproveDisabled] = useState(true);

    const [actionButtonList] = useState(component.actionButtonsList)
    const [formApiFields] = useState({
        apiUrl: component.apiUrl,
        apiUrlParamsList: component.apiUrlParamsList,
        propertiesList: component.propertiesList,
        apiUrlMethod: component.apiUrlMethod
    })

    function buttonActionHandler(item) {
        setSelectedButton(item)
        switch (item.buttonType) {
            case A8Utils.formBuilderFieldNames.primary:
                setShowPositiveModal(true)
                break;
            case A8Utils.formBuilderFieldNames.danger:
                setShowNegativeModal(true)
                break;
            default:
                break;
        }
    }

    function modalSubmitHandler(modalValues, file) {
        switch (selectedButton.buttonType) {
            case A8Utils.formBuilderFieldNames.primary:
                let payLoadParameterListPrimary = component.payloadParametersList.filter((item, index) => {
                    return item.buttonType === A8Utils.formBuilderFieldNames.primary;
                })
                apiCallhandler(selectedButton, modalValues, payLoadParameterListPrimary)
                break;
            case A8Utils.formBuilderFieldNames.danger:
                let payLoadParameterListDanger = component.payloadParametersList.filter((item, index) => {
                    return item.buttonType === A8Utils.formBuilderFieldNames.danger;
                })
                apiCallhandler(selectedButton, modalValues, payLoadParameterListDanger, file)
                break;
            default:
                break;
        }
    }


    function apiCallhandler(btnItem, modalValues, payLoadParameterList, file) {
        if (isBuilder) {
            return
        }

        let apiUrlParams = {};

        formApiFields.apiUrlParamsList.forEach((item) => {
            apiUrlParams[item.apiUrlParameter] = A8Utils.getColumnElementValue(value, item.apiUrlTargetKey)
        });
        let url = A8Utils.getAPiUrl(formApiFields.apiUrl, apiUrlParams)

        let apiBody = {
            taskKey: value.taskKey,
            action: btnItem.action,
            variables: {},
            submissionData: { ...value },
            formIoData: value.formIoData
        }

        formApiFields.propertiesList.forEach((item) => {
            apiBody.variables[item.propertyKey] = A8Utils.getColumnElementValue(value, item.propertyValue)
        });

        switch (btnItem.buttonType) {
            case A8Utils.formBuilderFieldNames.primary:
                payLoadParameterList.forEach((item, index) => {
                    apiBody[item.payloadParameterKey] = A8Utils.getColumnElementValue(modalValues, item.payloadParameterValue)
                })
                apiCall(url, apiBody, btnItem)
                break;
            case A8Utils.formBuilderFieldNames.danger:
                let formData = new FormData()

                payLoadParameterList.forEach((item) => {
                    let fieldType = component.negativeActionButtonComponentsList.filter((element) => {
                        return element.compoentFormNameParameter === item.payloadParameterValue
                    })[0].componentFieldType

                    if (fieldType === A8Utils.formBuilderFieldNames.upload) {
                        // let { originFileObj } = A8Utils.getColumnElementValue(modalValues, item.payloadParameterValue, "object")
                        // Check once with backend if file is receiving on their end.
                        formData.append(item.payloadParameterKey, file)
                    } else {
                        apiBody[item.payloadParameterKey] = A8Utils.getColumnElementValue(modalValues, item.payloadParameterValue)
                    }
                })

                let data = new Blob(
                    [JSON.stringify(apiBody)],
                    {
                        type: "application/json",
                    }
                )
                formData.append("data", data)

                // apiCall(url, formData, btnItem)
                // Revert changes after Oct10 Demo
                apiCall(url, apiBody, btnItem)
                break;

            default:
                break;
        }
    }

    function apiCall(url, apiBody, btnItem) {
        const token = localStorage.getItem('token');
        const tokenType = localStorage.getItem('tokenType')
        const modifiedConfig = {
            headers: {
                'Content-Type': 'application/json',
                ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
            },
        };

        fetch(url, {
            method: formApiFields.apiUrlMethod || 'POST',
            headers: modifiedConfig?.headers,
            // body: btnItem.buttonType === A8Utils.formBuilderFieldNames.primary ? JSON.stringify(apiBody) : apiBody,
            // Revert changes after Oct 10 Demo
            body:  JSON.stringify(apiBody) ,
        })
            .then((res) => res.json())
            .then((json) => {
                thiz.emit('submitButton', {
                    state: thiz.component.state || 'submitted',
                    component: thiz.component,
                    instance: thiz
                });
            })
            .catch((error) => {
                console.error(error);
            })
    }

    function invoiceUploadStatusApiCallHandler() {
        if (!component?.uploadInvoiceForm) {
            return
        }
        if (Object.entries(value).length !== 0) {
            let apiUrlParams = {};
            component?.invoiceUploadStatusApiUrlParamsList?.forEach((item) => {
                apiUrlParams[item.invoiceUploadStatusApiUrlParameter] = A8Utils.getColumnElementValue(value, item.invoiceUploadStatusApiUrlTargetKey);
            });
            if (component?.invoiceUploadStatusApiUrl) {
                let url = A8Utils.getAPiUrl(component.invoiceUploadStatusApiUrl, apiUrlParams);
                const token = localStorage.getItem("token");
                const tokenType = localStorage.getItem("tokenType");
                const modifiedConfig = {
                    headers: {
                        "Content-Type": "application/json",
                        ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
                    },
                };

                fetch(url, {
                    headers: modifiedConfig?.headers,
                })
                    .then((res) => res.json())
                    .then((json) => {
                        let mappedResponse = A8Utils.getColumnElementValue(json, component.invoiceUploadStatusApiUrlResponseDataKey);
                        setApproveDisabled(!mappedResponse)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }

    useEffect(() => {
        const observer = window.A8FormIoDataObserver;
        const subscription = observer.value.subscribe((newValue) => {
            setApproveDisabled(!newValue)
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!component?.uploadInvoiceForm) {
            setApproveDisabled(false)
        }
        let state = true;
        if (isBuilder) {
            state = true;
        }
        if (value['oid']) {
            state = true;
        }
        setReady(state)
        invoiceUploadStatusApiCallHandler()
        console.log('value  useEffect', state, value['oid'])
    }, [value['oid']])
    if (!ready) {
        return <>Loadding!!!!</>
    }

    console.log(value,'valuevalue')
    
    return (
        <>
        {value?.isReadOnly !== true 
        
        && <Row justify="end" align="middle" className='my-3'>
        {
            actionButtonList?.map((item) => {
                switch (item.buttonType) {
                    case A8Utils.formBuilderFieldNames.primary:
                        return (
                            <Col className='mx-2' key={item.name}>
                                <Button className='btn-positive' disabled={approveDisabled} onClick={() => { buttonActionHandler(item) }} size="large" type={`${item.buttonType}`} >{item.buttonLabel}</Button>
                            </Col>
                        )
                    case A8Utils.formBuilderFieldNames.danger:
                        return (
                            <Col className='mx-2' key={item.name}>
                                <Button className='btn-negative' onClick={() => { buttonActionHandler(item) }} size="large" danger>{item.buttonLabel}</Button>
                            </Col>
                        )
                    default:
                        return (
                            <Col className='mx-2' key={item.name}>
                                <Button className='btn-positive' onClick={() => { buttonActionHandler(item) }} size="large" type={`${item.buttonType}`} >{item.buttonLabel}</Button>
                            </Col>
                        )
                }
            })
        }
    </Row>

        }
            
            <PrimaryButtonModal
                component={component}
                showPositiveModal={showPositiveModal}
                setShowPositiveModal={setShowPositiveModal}
                modalSubmitHandler={modalSubmitHandler}
            />
            {showNegativeModal &&
                <DangerButtonModal
                    value={value}
                    component={component}
                    showNegativeModal={showNegativeModal}
                    setShowNegativeModal={setShowNegativeModal}
                    modalSubmitHandler={modalSubmitHandler}
                />
            }
        </>
    )
}

export default GridActionButtonsCustomComp