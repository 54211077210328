// Package Imports
import { MoneyCollectOutlined } from '@ant-design/icons';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const RuleDetailsResource: ResourceItem = {
  name: resourceName.ruleDetails,
  identifier: ModuleIdentifierConst.DASHBOARD_MAPPING,
  list: `${resourceName.ruleDetails}`,
  options: { label: 'Rule Details', icon: <MoneyCollectOutlined /> },
};

export default RuleDetailsResource;
