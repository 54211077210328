// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const img = (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_212_3558)">
      <path
        d="M0 3.47388C0.0532213 3.26493 0.0798319 3.05597 0.133053 2.84702C0.638655 1.14925 2.18207 0 3.96499 0C7.66387 0 11.3627 0 15.0616 0C17.0574 0 18.7339 1.43657 18.9734 3.39552C19 3.55224 19 3.70896 19 3.89179C19 5.98134 19 8.04478 19 10.1343C19 12.041 17.5896 13.6604 15.6737 13.9739C15.4608 14 15.2745 14.0261 15.0616 14.0261C11.3361 14.0261 7.63725 14.0261 3.91176 14.0261C1.96919 14 0.292717 12.5373 0.0266106 10.6306C0.0266106 10.6045 0 10.5522 0 10.5261C0 8.17537 0 5.82463 0 3.47388ZM9.5 12.4328C11.3361 12.4328 13.1723 12.4328 15.0084 12.4328C16.3922 12.4328 17.4034 11.4142 17.43 10.0821C17.43 8.01866 17.43 5.98134 17.43 3.91791C17.43 2.58582 16.3922 1.56716 15.035 1.56716C11.3361 1.56716 7.66387 1.56716 3.9916 1.56716C2.63445 1.56716 1.59664 2.58582 1.59664 3.91791C1.59664 5.98134 1.59664 8.01866 1.59664 10.0821C1.59664 10.1866 1.59664 10.3172 1.62325 10.4478C1.83613 11.6754 2.79412 12.4328 4.04482 12.4328C5.85434 12.4328 7.66387 12.4328 9.5 12.4328Z"
        fill="currentColor"
      />
      <path
        d="M6.33324 3.21265C6.33324 3.291 6.33324 3.36936 6.33324 3.4216C6.33324 4.46638 6.33324 5.53727 6.33324 6.58205C6.33324 7.07832 6.51951 7.49623 6.86545 7.8619C7.63716 8.59324 8.38226 9.35071 9.12735 10.082C9.18058 10.1343 9.2338 10.1604 9.28702 10.2126C8.35565 10.944 6.62595 11.2052 5.18898 10.4216C3.67217 9.6119 2.84724 7.83578 3.21979 6.19026C3.67217 4.30966 5.26881 3.34324 6.33324 3.21265Z"
        fill="currentColor"
      />
      <path
        d="M7.90332 3.18652C9.18063 3.47384 10.112 4.15294 10.6708 5.27608C11.3361 6.58205 11.203 7.8619 10.4313 9.14175C10.3781 9.08951 10.3249 9.03727 10.2717 8.98503C9.57979 8.30593 8.91452 7.62682 8.22265 6.97384C8.00976 6.76488 7.90332 6.52981 7.90332 6.24249C7.90332 5.3022 7.90332 4.3619 7.90332 3.39548C7.90332 3.34324 7.90332 3.26488 7.90332 3.18652Z"
        fill="currentColor"
      />
      <path
        d="M14.2631 9.32471C14.5292 9.32471 14.7687 9.32471 15.0348 9.32471C15.4872 9.32471 15.8331 9.66426 15.8331 10.0822C15.8331 10.5001 15.5138 10.8658 15.0614 10.8919C14.5292 10.918 13.9704 10.918 13.4382 10.8919C12.9858 10.8919 12.6399 10.5262 12.6665 10.1083C12.6665 9.66426 13.0124 9.35083 13.4914 9.35083C13.7309 9.32471 13.997 9.32471 14.2631 9.32471Z"
        fill="currentColor"
      />
      <path
        d="M14.2631 4.67538C13.997 4.67538 13.7575 4.67538 13.4914 4.67538C13.039 4.67538 12.6931 4.36194 12.6665 3.91791C12.6399 3.5 12.9858 3.13433 13.4382 3.10821C13.997 3.08209 14.5292 3.08209 15.088 3.10821C15.5404 3.10821 15.8598 3.5 15.8598 3.91791C15.8331 4.33582 15.4872 4.67538 15.0614 4.67538C14.7687 4.67538 14.5026 4.67538 14.2631 4.67538Z"
        fill="currentColor"
      />
      <path
        d="M14.2364 7.75735C13.9436 7.75735 13.6509 7.78347 13.3582 7.75735C12.9857 7.73123 12.6929 7.44392 12.6397 7.05213C12.6131 6.71258 12.8526 6.34691 13.1985 6.24243C13.2518 6.21631 13.305 6.21631 13.3582 6.21631C13.9436 6.21631 14.5025 6.21631 15.0879 6.21631C15.4072 6.21631 15.6999 6.45138 15.7798 6.76482C15.8596 7.07825 15.7798 7.4178 15.5137 7.60064C15.354 7.70511 15.1677 7.75735 14.9814 7.78347C14.742 7.80959 14.5025 7.78347 14.2364 7.75735Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_212_3558">
        <rect width="19" height="14" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const Augment8DashboardResource: ResourceItem = {
  name: resourceName.augment8Dashboard,
  identifier: resourceName.augment8Dashboard,
  list: `${resourceName.augment8Dashboard}`,
  options: { label: 'Augment8 Dashboard' },
  meta: {
    label: 'Augment8 Dashboard',
    icon: img,
  },
};

export default Augment8DashboardResource;
