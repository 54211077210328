// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';

import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';
import PrivateRouteConst from 'core/common/privateRouteConstant';

// Lazy-loaded Component
const SyncManagement = lazy(
  () => import('pages/syncManagement/syncManagement')
);

const SyncManagementRoute = (
  <Route
    path={routeName.syncManagement}
    element={
      <PrivateRoute name={PrivateRouteConst.SYNC_MANAGEMENT}>
        <Suspense fallback={<Loading />}>
          <SyncManagement />
        </Suspense>
      </PrivateRoute>
    }
  />
);

export default SyncManagementRoute;
