// Package Imports

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import PrivateRouteConst from 'core/common/privateRouteConstant';

const img = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.56857 1.9707C9.08436 1.9707 10.6001 1.9707 12.1159 1.9707C12.2001 1.99909 12.3124 1.99909 12.3966 2.02748C13.6317 2.22621 14.6984 2.76561 15.5966 3.67407C16.4668 4.58253 17.3651 5.46261 18.2352 6.34268C19.4142 7.50665 19.9756 8.92612 19.9756 10.6011C19.9756 12.7871 19.9756 15.0015 19.9756 17.1875C19.9756 17.443 19.9475 17.6985 19.9194 17.954C19.6668 19.8561 17.9545 21.3607 16.0738 21.3607C13.351 21.3607 10.6001 21.3607 7.87734 21.3607C7.68085 21.3607 7.48436 21.3323 7.28787 21.3039C5.29488 20.9065 4.00366 19.3167 3.97559 17.301C3.97559 13.5536 3.97559 9.80619 3.97559 6.05878C4.00366 4.29864 4.98611 2.82239 6.58611 2.22621C6.89488 2.11265 7.23173 2.05587 7.56857 1.9707ZM11.9756 3.5889C11.8633 3.5889 11.8072 3.5889 11.723 3.5889C10.4598 3.5889 9.19664 3.5889 7.93348 3.5889C7.45629 3.5889 7.00716 3.73085 6.61418 4.01474C5.91243 4.52575 5.57559 5.23549 5.57559 6.11556C5.57559 8.47189 5.57559 10.8282 5.57559 13.1561C5.57559 14.5188 5.57559 15.8815 5.57559 17.2442C5.54752 18.7205 6.7826 19.7425 8.04576 19.7425C10.6563 19.7141 13.2668 19.7425 15.9054 19.7425C16.0458 19.7425 16.1861 19.7425 16.3265 19.7141C17.5616 19.487 18.3756 18.5501 18.3756 17.2442C18.3756 15.0015 18.3756 12.7303 18.3756 10.4875C18.3756 10.3456 18.3756 10.2036 18.3475 10.0333C18.2633 10.0333 18.1791 10.0333 18.0949 10.0333C16.8879 10.0333 15.6528 10.0333 14.4458 10.0333C12.958 10.0617 11.9194 8.81256 11.9756 7.50665C12.0037 6.31429 11.9756 5.12193 11.9756 3.92958C11.9756 3.84441 11.9756 3.73085 11.9756 3.5889ZM13.6317 4.1283C13.6037 4.1283 13.6037 4.15669 13.5756 4.15669C13.5756 4.21347 13.5756 4.27025 13.5756 4.35542C13.5756 5.40583 13.6037 6.45624 13.5756 7.50665C13.5756 8.01766 13.8844 8.4435 14.5019 8.41511C15.5405 8.38672 16.5791 8.41511 17.6177 8.41511C17.6738 8.41511 17.73 8.41511 17.8423 8.41511C17.6458 8.15961 17.5054 7.93249 17.3089 7.73376C16.3265 6.71174 15.344 5.71811 14.3335 4.72448C14.137 4.49736 13.8844 4.32703 13.6317 4.1283Z"
      fill="currentColor"
    />
    <path
      d="M11.1894 15.9667C11.1894 15.9099 11.1894 15.8247 11.1894 15.7679C11.1894 14.6891 11.1894 13.6387 11.1894 12.5599C11.1894 12.1057 11.4139 11.7934 11.7789 11.6799C12.228 11.5663 12.7052 11.8786 12.7613 12.3612C12.7613 12.4464 12.7613 12.5599 12.7613 12.6451C12.7613 13.6955 12.7613 14.7175 12.7613 15.7679C12.7613 15.8247 12.7613 15.9099 12.7613 16.0234C12.8455 15.9383 12.8736 15.9099 12.9297 15.8815C13.2104 15.5976 13.5192 15.2853 13.7999 15.0014C14.1368 14.6891 14.5859 14.6891 14.9227 15.0014C15.2315 15.3137 15.2596 15.7963 14.9508 16.137C14.9227 16.1654 14.8947 16.1938 14.8666 16.2222C14.4736 16.6196 14.0525 17.0455 13.6596 17.4429C12.6771 18.4082 11.2455 18.4082 10.2631 17.4429C9.84202 17.0171 9.42097 16.5912 8.99991 16.1654C8.77535 15.9383 8.69114 15.6544 8.80342 15.3421C8.88763 15.0298 9.1122 14.8595 9.44904 14.8027C9.72974 14.7459 9.9543 14.8311 10.1508 15.0298C10.4876 15.3705 10.7964 15.6828 11.1332 16.0234C11.1332 15.995 11.1613 15.9667 11.1894 15.9667Z"
      fill="currentColor"
    />
  </svg>
);

const InputFileDownloadResource: ResourceItem = {
  name: resourceName.inputFileDownload,
  identifier: PrivateRouteConst.INPUT_FILE_DOWNLOAD,
  list: `${resourceName.inputFileDownload}`,
  meta: {
    label: 'Download',
    icon: img,
    parent: resourceName.fileManagement,
  },
};

export default InputFileDownloadResource;
