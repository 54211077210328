// Package Imports

// Project Imports
import PrivateRouteConst from 'core/common/privateRouteConstant';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const img = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4228 21.3908C13.49 21.3908 10.5296 21.3908 7.59681 21.3908C7.40314 21.3348 7.18179 21.3067 6.98812 21.2507C5.35572 20.8024 4 19.2892 4 17.2436C4.02767 13.5448 4 9.84599 4 6.14716C4 5.89497 4.02767 5.64278 4.05534 5.41861C4.33201 3.48513 6.01975 2 7.92882 2C9.20154 2 10.4466 2 11.7193 2C13.1304 2.02802 14.3754 2.50438 15.3991 3.51315C16.4505 4.4939 17.4742 5.55872 18.4979 6.59551C19.4386 7.57626 19.9643 8.78118 19.9643 10.1542C19.992 12.5921 20.0196 15.0299 19.9643 17.4678C19.9366 19.2892 18.7469 20.7743 17.0315 21.2507C16.8378 21.3067 16.6165 21.3348 16.4228 21.3908ZM11.996 3.59722C11.8853 3.59722 11.83 3.59722 11.747 3.59722C10.4743 3.59722 9.20154 3.59722 7.95649 3.59722C7.48614 3.59722 7.04345 3.73733 6.65611 4.01754C5.93674 4.54995 5.60473 5.25048 5.60473 6.11914C5.60473 8.52898 5.60473 10.9388 5.60473 13.3487C5.60473 14.6376 5.60473 15.9547 5.60473 17.2436C5.57706 18.7007 6.79444 19.7656 8.09483 19.7375C10.6956 19.7095 13.324 19.7375 15.9248 19.7375C16.1462 19.7375 16.3398 19.7375 16.5612 19.6815C17.6679 19.4013 18.4149 18.4766 18.4149 17.2717C18.4149 15.0019 18.4149 12.7322 18.4149 10.4625C18.4149 10.3224 18.4149 10.1822 18.3872 10.0421C18.3042 10.0421 18.2212 10.0421 18.1382 10.0421C16.9208 10.0421 15.7035 10.0421 14.4861 10.0421C13.0197 10.0702 11.9683 8.8092 11.996 7.52021C12.0237 6.28727 11.996 5.05433 11.996 3.79337C11.996 3.76535 11.996 3.7093 11.996 3.59722ZM17.8616 8.4169C17.6679 8.1647 17.5572 7.96856 17.3912 7.77241C16.3398 6.73561 15.2885 5.6708 14.2371 4.60599C14.0711 4.43786 13.8497 4.29775 13.6007 4.12963C13.6007 4.21369 13.6007 4.24171 13.6007 4.26973C13.6007 5.39059 13.6007 6.53946 13.6007 7.66032C13.6007 7.71636 13.6007 7.80043 13.6284 7.85647C13.7391 8.24877 14.0434 8.44492 14.4861 8.44492C15.5651 8.44492 16.6165 8.44492 17.6955 8.44492C17.7232 8.44492 17.7785 8.44492 17.8616 8.4169Z"
      fill="currentColor"
    />
    <path
      d="M12.798 13.853C12.798 13.909 12.798 13.9931 12.798 14.0491C12.798 15.1139 12.798 16.1787 12.798 17.2435C12.798 17.7199 12.5767 18.0281 12.134 18.1122C11.719 18.1963 11.2763 17.888 11.1933 17.4677C11.1933 17.4117 11.1933 17.2996 11.1933 17.1875C11.1933 16.1507 11.1933 15.0859 11.1933 14.0491C11.1933 13.9931 11.1933 13.909 11.1933 13.7969C11.1103 13.853 11.0826 13.909 11.0273 13.937C10.723 14.2172 10.4463 14.5255 10.1419 14.8057C9.80992 15.1139 9.3119 15.1139 9.00756 14.8057C8.70321 14.4974 8.70321 13.9931 9.00756 13.6848C9.45024 13.2085 9.92059 12.7321 10.3909 12.2838C11.2763 11.4431 12.715 11.4431 13.6004 12.3118C14.0708 12.7601 14.4858 13.2085 14.9285 13.6568C15.2605 13.9931 15.2605 14.4974 14.9285 14.8057C14.6241 15.1419 14.0984 15.1139 13.7664 14.7777C13.4344 14.4414 13.1301 14.1332 12.798 13.7969C12.8257 13.853 12.8257 13.853 12.798 13.853Z"
      fill="currentColor"
    />
  </svg>
);

const MasterFileUploadResource: ResourceItem = {
  name: resourceName.masterFileUpload,
  identifier: PrivateRouteConst.MASTER_FILE_UPLOAD,
  list: `${resourceName.masterFileUpload}`,
  meta: {
    label: 'Upload',
    icon: img,
    parent: resourceName.maintainence,
  },
};

export default MasterFileUploadResource;
