// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

import PrivateRouteConst from 'core/common/privateRouteConstant';

// Lazy-loaded Component
const SyncHistory = lazy(() => import('pages/syncHistroy/syncHistory'));

const SyncHistoryRoute = (
  <Route
    index
    path={routeName.syncHistory}
    element={
      <PrivateRoute name={PrivateRouteConst.SYNC_HISTORY}>
        <Suspense fallback={<Loading />}>
          <SyncHistory />
        </Suspense>
      </PrivateRoute>
    }
  />
);

export default SyncHistoryRoute;
