// Package Imports
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const img = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_212_3598)">
      <path
        d="M19.8518 2C19.9913 2.02789 20.1308 2.08368 20.2703 2.11158C21.3023 2.4463 22.0276 3.3947 21.9997 4.48257C21.9997 4.84519 21.9997 5.20781 21.8881 5.57043C21.6371 6.43515 20.8281 6.99303 19.9076 7.02092C19.7682 7.02092 19.6566 7.02092 19.4892 7.02092C19.4892 7.1046 19.4892 7.18828 19.4892 7.27197C19.4892 10.7866 19.4613 14.2734 19.4892 17.788C19.5171 19.7685 18.1224 21.6095 16.0025 21.9721C15.6398 22.0279 15.2493 22.0279 14.8588 22.0279C14.4683 22.0279 14.0778 22.0279 13.6594 22.0279C13.1852 22.0279 12.7947 21.6653 12.7947 21.219C12.7947 20.7727 13.1573 20.3822 13.6315 20.3822C14.1615 20.3822 14.6915 20.3822 15.2214 20.3822C16.5604 20.3822 17.5645 19.5174 17.7598 18.2064C17.7877 18.0669 17.7877 17.9554 17.7877 17.8159C17.7877 14.3013 17.7877 10.7866 17.7877 7.27197C17.7877 7.21618 17.7877 7.1325 17.7877 7.04882C17.704 7.04882 17.6203 7.04882 17.5366 7.04882C15.2772 7.04882 13.0178 7.04882 10.7305 7.04882C9.69843 7.04882 8.86161 6.37936 8.63845 5.34728C8.61056 5.17992 8.61056 4.98466 8.58267 4.81729C8.52688 4.14784 7.9411 3.67364 7.24375 3.72943C6.63009 3.75732 6.1001 4.28731 6.1001 4.92887C6.1001 5.90516 6.1001 6.88145 6.1001 7.82985C6.1001 8.27615 5.84905 8.61088 5.40275 8.69456C4.92855 8.80614 4.42646 8.41562 4.42646 7.94142C4.42646 6.85356 4.39857 5.79358 4.42646 4.70572C4.48225 3.45049 5.54222 2.27894 6.79745 2.05579C6.85324 2.05579 6.88113 2.02789 6.93692 2C11.3163 2 15.5841 2 19.8518 2ZM10.1168 3.67364C10.2005 4.06416 10.2842 4.42678 10.3121 4.7894C10.3679 5.2357 10.4516 5.31939 10.8979 5.31939C13.7988 5.31939 16.7277 5.31939 19.6287 5.31939C20.2424 5.31939 20.2981 5.2636 20.326 4.62204C20.3539 4.03626 19.9355 3.67364 19.3776 3.67364C16.3651 3.67364 13.3246 3.67364 10.3121 3.67364C10.2284 3.67364 10.1726 3.67364 10.1168 3.67364Z"
        fill="currentColor"
      />
      <path
        d="M2 12.8508C2.02789 12.7392 2.05579 12.5997 2.08368 12.4882C2.41841 11.2329 3.56206 10.3403 4.90098 10.3403C6.29568 10.3403 7.69038 10.3403 9.11297 10.3403C10.7308 10.3403 12.0139 11.6514 12.0139 13.2692C12.0139 15.2218 12.0139 17.1744 12.0139 19.099C12.0139 20.7169 10.7308 22.0279 9.08508 22.0279C7.71827 22.0279 6.32357 22.0279 4.95676 22.0279C3.50628 22.0279 2.30683 21.0237 2.05579 19.5732C2.05579 19.5454 2.02789 19.5175 2.02789 19.4896C2 17.258 2 15.0544 2 12.8508ZM10.3403 16.1702C10.3403 15.2218 10.3403 14.2734 10.3403 13.325C10.3403 12.5161 9.81032 11.9861 9.0014 11.9861C7.66248 11.9861 6.35146 11.9861 5.01255 11.9861C4.20363 11.9861 3.67364 12.5161 3.67364 13.325C3.67364 15.2218 3.67364 17.1186 3.67364 19.0154C3.67364 19.8243 4.17573 20.3264 4.98466 20.3264C6.32357 20.3264 7.66248 20.3264 9.0014 20.3264C9.81032 20.3264 10.3124 19.7964 10.3124 19.0154C10.3403 18.067 10.3403 17.1186 10.3403 16.1702Z"
        fill="currentColor"
      />
      <path
        d="M6.99288 14.4964C6.435 14.4964 5.90502 14.4964 5.34714 14.4964C4.87294 14.4964 4.51032 14.1338 4.48242 13.6875C4.48242 13.2133 4.81715 12.8228 5.29135 12.8228C6.40711 12.8228 7.52287 12.8228 8.63863 12.8228C9.11283 12.8228 9.47545 13.2133 9.44756 13.6875C9.44756 14.1338 9.08493 14.4964 8.61073 14.4964C8.10864 14.4964 7.55076 14.4964 6.99288 14.4964Z"
        fill="currentColor"
      />
      <path
        d="M5.54207 17.0628C5.31892 17.0071 5.09576 16.9792 4.90051 16.8676C4.59367 16.7002 4.4821 16.3934 4.50999 16.0308C4.53788 15.696 4.81682 15.4171 5.12366 15.3613C5.4026 15.3334 5.68154 15.3055 5.93258 15.3613C6.35099 15.445 6.60204 15.8355 6.57415 16.2818C6.51836 16.7002 6.18363 17.0071 5.76522 17.0071C5.68154 17.0071 5.62575 17.0071 5.54207 17.0071C5.54207 17.0071 5.54207 17.035 5.54207 17.0628Z"
        fill="currentColor"
      />
      <path
        d="M5.51482 19.5454C5.29167 19.4896 5.06851 19.4617 4.87326 19.3501C4.56642 19.1828 4.42695 18.8759 4.48274 18.5133C4.53853 18.1786 4.73379 17.9275 5.09641 17.8438C5.34745 17.7881 5.65429 17.7881 5.90533 17.8438C6.35164 17.9275 6.60268 18.318 6.5469 18.7365C6.49111 19.1549 6.15638 19.4617 5.73797 19.4617C5.65429 19.4617 5.5985 19.4617 5.51482 19.4617C5.54271 19.5175 5.54271 19.5454 5.51482 19.5454Z"
        fill="currentColor"
      />
      <path
        d="M8.47111 17.0071C8.33164 17.0071 8.22006 17.0071 8.08059 16.9792C7.69007 16.8955 7.41113 16.5608 7.41113 16.1702C7.41113 15.7797 7.66218 15.4171 8.0527 15.3613C8.30374 15.3334 8.58268 15.3055 8.83373 15.3613C9.25214 15.445 9.53108 15.8355 9.47529 16.2818C9.4195 16.6723 9.08477 17.0071 8.66636 17.0071C8.61057 17.0071 8.52689 17.0071 8.47111 17.0071Z"
        fill="currentColor"
      />
      <path
        d="M8.47111 19.5175C8.33164 19.5175 8.22006 19.5175 8.08059 19.4896C7.69007 19.4059 7.43903 19.0712 7.41113 18.6807C7.41113 18.2902 7.66218 17.9275 8.0527 17.8718C8.30374 17.8439 8.58268 17.8439 8.83373 17.8718C9.25214 17.9554 9.53108 18.3459 9.47529 18.7923C9.4195 19.1828 9.08477 19.5175 8.66636 19.5175C8.61057 19.5175 8.52689 19.5175 8.47111 19.5175Z"
        fill="currentColor"
      />
      <path
        d="M14.6357 9.8941C14.0499 9.8941 13.4921 9.8941 12.9063 9.8941C12.4042 9.8941 12.0137 9.53148 12.0137 9.08517C12.0137 8.61098 12.3763 8.22046 12.8784 8.22046C14.0499 8.22046 15.2494 8.22046 16.4209 8.22046C16.923 8.22046 17.2856 8.61098 17.2856 9.08517C17.2856 9.53148 16.8951 9.8941 16.393 9.8941C15.8073 9.8941 15.2215 9.8941 14.6357 9.8941Z"
        fill="currentColor"
      />
      <path
        d="M15.3051 13.5203C14.8867 13.5203 14.4404 13.5203 14.022 13.5203C13.6594 13.5203 13.3525 13.1577 13.3525 12.7114C13.3525 12.2372 13.6315 11.8467 13.9941 11.8467C14.8588 11.8467 15.7514 11.8467 16.6161 11.8467C16.9788 11.8467 17.2577 12.2372 17.2577 12.7114C17.2577 13.1577 16.9788 13.5203 16.5882 13.5203C16.1698 13.5203 15.7514 13.5203 15.3051 13.5203Z"
        fill="currentColor"
      />
      <path
        d="M15.8079 17.1465C15.5011 17.1465 15.1663 17.1465 14.8595 17.1465C14.5806 17.1465 14.3574 16.7839 14.3574 16.3376C14.3574 15.8634 14.5527 15.4729 14.8316 15.4729C15.4732 15.4729 16.1426 15.4729 16.7842 15.4729C17.0631 15.4729 17.2584 15.8634 17.2584 16.3376C17.2584 16.7839 17.0352 17.1465 16.7563 17.1465C16.4774 17.1465 16.1426 17.1465 15.8079 17.1465Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_212_3598">
        <rect
          width="20"
          height="20"
          fill="currentColor"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
);

const AccountingResource: ResourceItem = {
  name: resourceName.accounting,
  identifier: ModuleIdentifierConst.ACCOUNTING,
  list: `${resourceName.accounting}`,
  options: { label: 'Accounting', icon: img },
};

export default AccountingResource;
