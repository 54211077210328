// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import Loading from 'components/loading/loading';

// Lazy-loaded Component
const RuleDetails = lazy(() => import('pages/ruleDetails/ruleDetails'));

const RuleDetailsRoute = (
  <Route path={routeName.ruleDetails}>
    <Route
      index
      path=":id"
      element={
        // <PrivateRoute name={PrivateRouteConst.ACCOUNTING}>
        <Suspense fallback={<Loading />}>
          <RuleDetails />
        </Suspense>
        // </PrivateRoute>
      }
    />
  </Route>
);

export default RuleDetailsRoute;
